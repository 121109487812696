<template lang='pug'>
div.no-scroll(v-if="betweenSizeLimited")
  MobileHomeComponent(id='mobile-home')
  MobileAboutUsComponent(id='mobile-quem-somos')
  MobileConsultoriaComponent(id='mobile-consultoria')
  MobileRecrutamentoComponent(id='mobile-recrutamento')
  MobileTreinamentoComponent(id='mobile-treinamento')
  MobileDepoimentosComponent(id='mobile-depoimentos')
  //- MobileFabulousComponent
  //- MobileInegociavelComponent
  //- MobileExecutiveSearchComponent
  //- MobileImportantComponent
  //- MobileInvestimentComponent
  //- MobileFooterComponent
div(v-else)
  HeaderComponent
  HomeComponent(:screenWidth="this.screenWidth")
  AboutUsComponent(id='quem-somos')
  ConsultoriaComponent(id='consultoria')
  RecrutamentoComponent(id='recrutamento')
  TreinamentoComponent(id='treinamento')
  DepoimentosComponent(id='depoimentos')
  //- MarketSituationComponent
  //- StatisticsComponent
  //- NonNegotiableComponent
  //- MarketPictureComponent
  //- ServiceComponent
  //- LeaderComponent
  //- MeetComponent(:screenWidth="this.screenWidth")
  //- ChallengesComponent
  //- FooterComponent
</template>
<script>
import MobileHeaderComponent from '../components/MobileHeaderComponent';
import MobileHomeComponent from '../components/MobileHomeComponent';
import MobileAboutUsComponent from '../components/MobileAboutUsComponent';
import MobileConsultoriaComponent from '../components/MobileConsultoriaComponent';
import MobileRecrutamentoComponent from '../components/MobileRecrutamentoComponent';
import MobileTreinamentoComponent from '../components/MobileTreinamentoComponent';
import MobileDepoimentosComponent from '../components/MobileDepoimentosComponent';
import MobileFabulousComponent from '../components/MobileFabulousComponent';
import MobileInegociavelComponent from '../components/MobileInegociavelComponent';
import MobileExecutiveSearchComponent from '../components/MobileExecutiveSearchComponent';
import MobileImportantComponent from '../components/MobileImportantComponent';
import MobileInvestimentComponent from '../components/MobileInvestimentComponent';
import MobileFooterComponent from '../components/MobileFooterComponent';
import HeaderComponent from '../components/HeaderComponent';
import HomeComponent from '../components/HomeComponent';
import AboutUsComponent from '../components/AboutUsComponent';
import ConsultoriaComponent from '../components/ConsultoriaComponent';
import RecrutamentoComponent from '../components/RecrutamentoComponent';
import TreinamentoComponent from '../components/TreinamentoComponent';
import DepoimentosComponent from '../components/DepoimentosComponent';
import MarketSituationComponent from '../components/MarketSituationComponent';
import StatisticsComponent from '../components/StatisticsComponent';
import NonNegotiableComponent from '../components/NonNegotiableComponent';
import ServiceComponent from '../components/ServiceComponent';
import FooterComponent from '../components/FooterComponent';
import MeetComponent from '../components/MeetComponent';
import ChallengesComponent from '../components/ChallengesComponent';
// import LeaderComponent from '../components/LeaderComponent';
import MarketPictureComponent from '../components/MarketPictureComponent';

export default {
  components: {
    MobileHeaderComponent,
    MobileHomeComponent,
    MobileAboutUsComponent,
    MobileConsultoriaComponent,
    MobileRecrutamentoComponent,
    MobileTreinamentoComponent,
    MarketPictureComponent,
    MobileDepoimentosComponent,
    MobileFabulousComponent,
    MobileInegociavelComponent,
    MobileExecutiveSearchComponent,
    MobileImportantComponent,
    MobileInvestimentComponent,
    MobileFooterComponent,
    MeetComponent,
    HeaderComponent,
    HomeComponent,
    ConsultoriaComponent,
    RecrutamentoComponent,
    TreinamentoComponent,
    DepoimentosComponent,
    FooterComponent,
    // LeaderComponent,
    AboutUsComponent,
    ServiceComponent,
    StatisticsComponent,
    NonNegotiableComponent,
    ChallengesComponent,
    MarketSituationComponent,
  },

  data() {

    return {
    };

  },

  async created() {

    window.addEventListener('resize', this.getScreen);
    this.screenWidth = window.window.innerWidth;
    await this.getScreen();

  },
  async destroyed() {

    this.screenWidth = window.window.innerWidth;
    window.removeEventListener('resize', this.getScreen);

  },

  methods: {
    getScreen() {

      this.screenWidth = window.window.innerWidth;
      const sizeScreenWidth = window.innerWidth;
      this.betweenSizeLimited = !!((sizeScreenWidth <= 1000));

    },
  },

};
</script>
<style lang='css'>
.no-scroll {
  overflow: hidden;
}
</style>
