<template lang='pug'>
div
    b-row.max-width(style="margin: 0px;")
      b-col.col-xs-12.col-md-12.col-lg-12.col-xl-6(style="padding: 0px; text-align: center;")
        div.screen-non-negotiable
      b-col.background.col-xs-12.col-md-12.col-lg-12.col-xl-6
        b-row.mt-4.p-3.text-non-negotiable
          b-col
            b-row.title-text-non-negotiable
              b-col.col-12(style="padding: 0px; text-align: center;")
                label Inegociável
            br
            b-row.content-text-non-negotiable(style="padding: 0px; text-align: center;")
              b-col.col-12
                label Temos como premissa a condução de uma
              b-col.col-12
                label uma experiência diversa, plural,
              b-col.col-12
                label humanizada, transparente com
              b-col.col-12
                label metodologia proprietária limpa de viéses,
              b-col.col-12
                label tendo um SLA qualificado, construindo
              b-col.col-12
                label uma relação saudável de longo prazo,
              b-col.col-12
                label com todas as pessoas, sendo elas
              b-col.col-12
                label representadas por CPF ou CNPJ.
            b-row.mt-5
              b-col.col-12(style="display: flex; justify-content: end")
                img(src="../assets/img/RHUB_fundo_transparente__Principal-com-contorno.webp" width="165" height="165" alt="RHUB Logo Icon")
</template>
<script>

export default {

  data() {

    return {};

  },

};
</script>
<style lang='css'>
  .screen-non-negotiable {
    height: 100vh;
    max-height: 980px;
    background-position: center;
    width: 100%;
    background-image: url('../assets/img/inegociavel-picture.png');
  }
  .background {
    background: rgb(30 34 37);
  }
  .text-non-negotiable {
    display: flex;
    justify-content: left;
    color: white;
    text-align: left;
  }
  .title-text-non-negotiable {
    font-size: 70px;
    font-weight: 700;
  }
  .content-text-non-negotiable {
    font-size: 26px;
    line-height: 50px;
  }
</style>
