<template lang='pug'>
div.second-divider
  b-col.col-12.p-4
    label(style="color: black; font-weight: 600; display: flex; text-align: left; font-size: 25px; padding-left: 8px; margin-top: 20px") &nbsp; Quem somos
    div
      b-col.col-12
        label.p-4(style="color: black; font-size: 27px; display: flex; text-align: left;") RHub é a união
          | &nbsp; profissional de duas mulheres brilhantes Andreza Silva e Deborah Kaufmann,
          | que decidiram encapsular todo o arsenal de conhecimentos e experiências, para dar ao
          | mercado algumas soluções, mostrando que é possível construir para e com as pessoas,
          | experiências com resultados significativos em ambientes de forte aceleração. Sem ferir,
          | adoecer ou esconder a singularidade e pluralidade que todos nós temos.
      b-col.center.mt-4.p-4
        label(style="color: black; font-size: 25px; font-weight: 600") Visualizamos o futuro para potencializar o presente.
          br
          | Transformar a maneira como as pessoas trabalham, essa sim, é a vantagem competitiva.
    b-col.col-xs-12.col-md-12.col-lg-12.center.mt-4.second-divider
      b-row.mb-2.mt-5
        b-col.col-6
          b-row
            b-col.col-xs-12.col-md-12.col-lg-12.center
              b-avatar(:src="AndressaPngAvatar" size='14rem')
          b-row.mt-2
            b-col.col-xs-12.col-md-12.col-lg-12.center
              label(style="color: rgb(0, 0, 0); font-weight: bold;") Andreza Silva
            b-col.col-xs-12.col-md-12.col-lg-12.center
              label(style="color: rgb(0, 0, 0)") Founder
            b-col.col-xs-12.col-md-12.col-lg-12.center
              b-avatar(:href="urlLinkedinAndreza" target="_blank" rounded='sm' :src="LinkedinIcon" size='1.7rem' style="cursor: pointer;")
        b-col.col-6
          b-row
            b-col.col-xs-12.col-md-12.col-lg-12.center
              b-avatar(:src="DeboraPngAvatar" size='14rem')
          b-row.mt-2
            b-col.col-xs-12.col-md-12.col-lg-12.center
              label(style="color: rgb(0, 0, 0); font-weight: bold;") Débora Kaufmann
            b-col.col-xs-12.col-md-12.col-lg-12.center
              label(style="color: rgb(0, 0, 0)") Co-Founder
            b-col.col-xs-12.col-md-12.col-lg-12.center
              b-avatar(rounded='sm' :src="LinkedinIcon" size='1.7rem')

</template>
<script>
import DeboraPngAvatar from '../assets/img/debora-avatar.webp';
import AndressaPngAvatar from '../assets/img/andressa-avatar.webp';
import LinkedinIcon from '../assets/img/linkedin.webp';

export default {

  components: {
    AndressaPngAvatar,
    DeboraPngAvatar,
    LinkedinIcon,
  },

  data() {

    return {
      AndressaPngAvatar,
      DeboraPngAvatar,
      LinkedinIcon,
      urlLinkedinAndreza: 'https://www.linkedin.com/in/andrezakarendasilva/',
    };

  },

};
</script>
<style lang='css'>
  .second-divider {
    margin-bottom: 50px;
  }
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  img {
    height: 165px;
  }
  .title-second-divider {
    color: rgb(209, 0, 118);
    font-size: 70px;
    font-weight: 1000;
  }
  .highlight-content {
    font-weight: bold;
    font-family: system-ui;
    font-size: 21px;
  }
  .content-text-simple {
    font-weight: 300;
    font-size: 21px;
    padding-top: 5px;
  }
  .statistics-numbers-emphasis {
    color: rgb(209, 0, 118);
    font-weight: bold;
    font-size: 19px;
  }
  .info {
    font-style: italic;
    font-weight: 300;
    font-size: 13px;
    color: rgb(138, 138, 138);
  }
  .statistics-numbers {
    color: rgb(209, 0, 118);
    font-size: 70px;
    font-weight: 1000;
    letter-spacing: 5px;
  }
  .max-width {
    max-width: 100%;
  }
  .left {
    display: flex;
    justify-content: left;
  }
</style>
