<template lang='pug'>
div.challenges-div.p-5
  b-row.h-100(style="text-align: center;")
    b-col.col-lg-12.col-xl-6.p-5.center
      label.statistics-numbers-white(style="text-shadow: 2px 2px #000000;") EXERGAMOS ALÉM DAS FRONTEIRAS, AS FAMOSAS BARREIRAS "INVISÍVEIS"
</template>
<script>
export default {

};
</script>
<style lang='css'>

  .center {
    display: flex;
    justify-content: center;
  }
  .statistics-numbers-white {
    color: rgb(255, 255, 255);
    font-size: 70px;
    font-weight: 1000;
    letter-spacing: 5px;
  }
  .challenges-div {
    background-image: url('../assets/img/challenges.webp');
    max-height: 1280px;
    background-position: center;
  }
</style>
