<template lang='pug'>
  div.full.first-divider.flex-box
    b-row.center
      b-col.col-12.mb-4
        label.animation(style="font-weight: 700; text-align: center; color: white; font-size: 45px; margin-top: 200px")
          | RHub é uma HRTech humana,
          br
          | inteligente e ágil.
        b-col.col-12.mt-4
          label.p-4.animation(style="font-weight: 600; display: flex; center: left; color: white; font-size: 35px;") &nbsp; Somos a conexão entre pessoas e negócios
            br
            |&nbsp; proporcionando uma experiência eficaz e única
            br
            |&nbsp; em toda jornada de talento.
      //- b-col.col-12-mt-5.p-4
      //-   label.p-4.animation(style="font-weight: 600;display: flex; text-align: left; color: white; font-size: 35px;") &nbsp; Somos a conexão entre pessoas e negócios
      //-     br
      //-     |&nbsp; proporcionando uma experiência eficaz e única
      //-     br
      //-     |&nbsp; em toda jornada de talento.
</template>
<script>

export default {

  props: {
    screenWidth: Number,
  },

  data() {

    return {};

  },

};
</script>
<style lang='scss' scoped>
  .black {
    background-color: black;
    color: black;
  }
  .icon-width {
    width: 120px;
    height: 120px;
  }
  .first-divider-info {
    height: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .first-divider {
    background-image: url('../assets/img/foto-cidade-ok.webp');
    height: 80vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../assets/img/foto-cidade-ok.webp');
    height: 110vh;
    max-height: 960px;
  }
  .area-logo {
    max-width: 150px;
    max-height: 150px;
  }
  .none {
    display: none;
  }
  .vl {
    border-left: 5px solid rgb(255, 255, 255);
    height: 150px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .subtitle-first-divider {
    color: white;
    font-size: 25px;
  }
  .max-width {
    max-width: 100%;
  }
  .full {
    width: 100%;
  }
  .text-center {
    text-align: center;
  }
.max-width {
    max-width: 1235px;
    margin: 0 auto;
    width: 100%;
}

.calc--header-navbar {
    color: white;
    background-image: linear-gradient(
        180deg,
        rgba(10, 30, 71, 0.5) 0%,
        rgba(39, 52, 114, 0) 100%
    );
    min-height: 100px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;

    .navbar--desktop {
        display: flex;
        height: 100%;

        .max-width {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .col-links {
            flex: 1;
            max-width: 800px;

            .links--list {
                display: flex;
                justify-content: space-between;
                padding-left: 0;
                list-style: none;
                display: flex;
                flex-direction: row;
                margin-bottom: 0;

                .links--item {
                    display: flex;
                    align-items: center;
                    font-weight: 600;

                    a {
                        color: unset;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .navbar--mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .popup-navbar--mobile {
        position: fixed;
        z-index: 1;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        background-color: rgba(39, 52, 114, 0.98);
        padding: 0 15px;
        display: flex;
        flex-direction: column;

        .topbar-wrapper {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .midbar-wrapper {
            flex: 1;

            .links--list {
                padding-left: 0;
                list-style: none;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .links--wrapper {
                    .links--item {
                        font-size: 24px;
                        padding-bottom: 35px;
                        text-align: center;

                        a {
                            color: unset;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
