<template lang='pug'>
div.div-service
  b-row.max-width(style='margin: 0px;')
    b-col.col-xs-12.col-md-12.col-lg-12.col-xl-7.gray-background.p-5
      b-row.max-width
        b-col.col-12
          h1(style="color: white; font-size: 70px; font-weight: 1000;") Nossos Serviços
      b-row(style="width: 100%").mt-5
        b-col.col-3(style="display: flex; justify-content: right;")
          img(src="../assets/img/executive-icon.webp" width="130" height="130" alt="RHUB Executive Icon" style="width: 130px; height: 130px;")
        b-col.col-9(style="text-align: initial;")
          label(style="color: white; font-size: 44px; font-weight: 1000;") Recrutamento-Seleção & Executive Search
      b-row.mt-4(style="width: 100%")
        b-col.col-12.center
          b-card.mb-2.card-statistics-mobile.center(style='opacity: 55%')
            b-card-text.centered.h-100
              label.nossos-servicos-text Especialistas
        b-col.col-12.center.mt-3
          b-card.mb-2.card-statistics-mobile.center(style='opacity: 70%')
            b-card-text.centered.h-100
              label.nossos-servicos-text Média Gestão
        b-col.col-12.center.mt-3
          b-card.mb-2.card-statistics-mobile.center(style='opacity: 90%')
            b-card-text.centered.h-100
              label.nossos-servicos-text Alta Gestão

    b-col.col-xs-12.col-md-12.col-lg-12.col-xl-5.div-service-background
</template>
<script>

export default {

  data() {

    return {
      executive_text: '',
      leader_text: '',
      emocional_text: '',
      rh_text: '',
    };

  },

  async mounted() {

    this.executive_text = 'Nossa metodologia é proprietária limpa de viéses, temos um SLA qualificado,'
    + 'construindo uma relação saudável de longo prazo, com todas as pessoas,'
    + 'sendo elas representadas por CPF ou CNPJ.';

    this.leader_text = 'Elevar a consciência de lideres e potenciais lideres através'
    + 'de práticas integrativas de negócios e pessoas, seja no remoto ou presen';

    this.rh_text = 'RH as a service tem como principal objetivo, suprir as necessidades das'
    + 'empresas referente a espinhal dorsal do tema Pessoas, com midset de'
    + 'produtos de alta qualidade e eficiência.';

    this.emocional_text = 'Quebra de tabu sobre o estigma doença mental através roda de conversa,'
    + 'workshops, junto com a identificação de indutores e promotores do'
    + 'adoecimento, realizamos sugestões de intervenções de negócio, reestruturação'
    + 'de politicas, regras, programas de reabilitação e retorno licença maternidade';

  },

};
</script>
<style lang='css'>
  .content-text-simple {
    font-weight: 300;
    font-size: 18px;
  }
  .max-width {
    max-width: 100%;
  }
  .gray-background {
    background-image: linear-gradient(to right, rgb(30, 34, 37), rgb(30, 34, 38));
  }
  .div-service-background {
    background-image: linear-gradient(to right, rgba(30, 34, 37, 1.0), rgba(30, 34, 37, 0.3)), url('../assets/img/service-background.webp');
    height: 100vh;
    max-height: 1200px;
    background-position-x: 70%;
  }
  .nossos-servicos-text {
    color: white;
    font-size: 20px;
  }
</style>
