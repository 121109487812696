import { render, staticRenderFns } from "./ConsultoriaComponent.vue?vue&type=template&id=6d1f95c8&lang=pug&"
var script = {}
import style0 from "./ConsultoriaComponent.vue?vue&type=style&index=0&id=6d1f95c8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports