<template lang='pug'>
div.meet-div.centered
  div.mt-5.mb-5.h-100.flex
    b-row.py-3(style="max-width: 100%;")
      b-col.col-lg-12.col-xl-6
        b-row.max-width
          b-col.col-12
            h1(style="color: white; font-size: 70px; font-weight: 1000;") Formação de
            h1(style="color: white; font-size: 70px; font-weight: 1000;") saúde mental
            h1(style="color: white; font-size: 70px; font-weight: 1000;") e emocial
          b-col.col-12.mt-4
            label.content-text-simple(style="color: white") Precisamos reavaliar nossos modelos de organização, transformando o trabalho em um promotor da saúde
          b-row.max-width
          b-col.col-12.mt-4
            label.content-text-simple(style="color: white") {{ formacao_t2 }}
          b-col.col-12.mt-4
            label.content-text-simple(style="color: white") {{ formacao_t3 }}
      b-col.col-lg-12.col-xl-6
        b-row.center
          b-col.col-lg-4.col-xl-12.p-3
            b-row(style="diplay:flex; align-items: center;")
              b-col.col-lg-12.col-xl-6(:class="[screenWidth >= 1199 ? 'end' : 'center']")
                img(src="../assets/img/brain-icon-img.webp" width="165" height="165" alt="RHUB Brain Heart Icon")
              b-col.col-lg-12.col-xl-6.text-center
                label.emocional-info(style="color: white;") Primaria
                label.emocional-info(style="color: white") (sensibilização)
          b-col.col-lg-4.col-xl-12.p-3
            b-row(style="diplay:flex; align-items: center;")
              b-col.col-lg-12.col-xl-6(:class="[screenWidth >= 1199 ? 'end' : 'center']")
                img(src="../assets/img/mirror-icon.webp" width="165" height="165" alt="RHUB Mirror Icon")
              b-col.col-lg-12.col-xl-6.text-center
                label.emocional-info(style="color: white") Secundária
                label.emocional-info(style="color: white") (cuidado)
          b-col.col-lg-4.col-xl-12.p-3
            b-row(style="diplay:flex; align-items: center;")
              b-col.col-lg-12.col-xl-6(:class="[screenWidth >= 1199 ? 'end' : 'center']")
                img(src="../assets/img/engrenage-icon.webp" width="165" height="165" alt="RHUB Gear Icon")
              b-col.col-lg-12.col-xl-6.text-center
                label.emocional-info(style="color: white") Terciária
                label.emocional-info(style="color: white") (estrutura sustentável)
      b-row.w-100(style="display: flex; justify-content: end")
        div(style="max-width: 230px; max-height: 230px;")
          img(src="../assets/img/RHUB_fundo_transparente__EscalaCinza.webp" width="165" height="165" alt="RHUB Logo Icon")
</template>
<script>

export default {

  props: {
    screenWidth: Number,
  },

  data() {

    return {
      formacao_t2: '',
      formacao_t3: '',
    };

  },

  async mounted() {

    this.formacao_t2 = 'Sabia que a Ergonomia é ampla e que a maioria das empresas'
    + 'brasileira á subjuga pois entendemos ser aplicável'
    + 'somente ao aparelho muscular e talvez pelo automatismo consideram'
    + 'o cérebro como um músculo, o que não é.';

    this.formacao_t3 = 'Nossa proposta é moldada em uma intervenção integrada em Saúde'
    + 'Mental e Emocional, possibilitando o aumento da saúde pois atuamos na tríade:';

  },

};
</script>
<style lang='css'>
  .emocional-info {
    font-weight: 500;
    font-size: 27px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .max-width {
    max-width: 100%;
  }
  .centered{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .meet-div {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../assets/img/meeting-picture.webp');
    background-position: center;
  }
</style>
