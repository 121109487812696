<template lang='pug'>
div.first-divider
  MobileHeaderComponent
  b-col.col-12
    b-row
    label.animation.home-title(style='margin-top: 5rem')
      | RHub é uma
      br
      | HRTech humana,
      br
      | inteligente e ágil.
  b-col.mt-1.p-2
    label.home-text-info.animation(style='margin-bottom: 2rem') Somos a conexão entre
      br
      | pessoas e negócios
      br
      | proporcionando uma
      br
      | experiência eficaz e
      br
      | única em toda jornada
      br
      | de talento.
    //- b-row.center
    //-   b-col.mb-5.p-4.mt-4
    //-     label(style="font-weight: 700; text-align: center; color: white; font-size: 45px;")
    //-       | RHub é uma HRTech humana, inteligente e ágil.
      b-col.mt-5.p-4
        label.home-text-info Somos a conexão entre pessoas e negócios proporcionando uma
        label.home-text-info experiência eficaz e única em toda jornada de talento.
  //- div.etapa-1.p-3
  //-   b-row
  //-     b-col.col-12
  //-       b-row
  //-         b-col.col-12.center.mt-4
  //-           div.area-logo
  //-             img(src="../assets/img/RHUB_fundo_transparente__Principal-com-contorno.png")
  //-   b-col.col-12
  //-   b-row
  //-      b-col.col-12
  //-       label(style="font-size: 50px; color: white") UMA VERSÃO POTENTE DE
  //-       label(style="font-size: 50px; color: white; font-weight: bold; text-shadow: 2px 2px #000000;") ACELERAR PESSOAS E NEGÓCIOS
</template>

<script>
import MobileHeaderComponent from '../components/MobileHeaderComponent';

export default {
  components: {
    MobileHeaderComponent,
  },
};
</script>

<style lang='css'>
  .home-title {
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 2.9rem;
    margin-top: 10px;
    padding: 8px 8px 10px 8px
  }
  .home-text-info {
    font-weight: 500;
    text-align: left;
    color: white;
    font-size: 2rem;
    margin: 0;
  }
  label {
    font-family: opensauce semibold;
  }
  .mobile-text-animation {
    animation-duration: 5s;
    animation-name: slidein;
  }
  @keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
    }
  }
  .first-divider-info {
    height: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .first-divider {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/img/foto-cidade-ok.webp');
    background-position-x: 30%;
  }
  .animation {
    animation: go-back 2s;
}
  @keyframes go-back {
    0% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(0);
    }
}
</style>
