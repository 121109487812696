<template lang='pug'>

div.app
  router-view

</template>
<script>

export default {
  name: 'HomeSite',
};
</script>
<style>
  .app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
  }
</style>
