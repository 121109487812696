<template lang='pug'>
div.second-divider.mt-4
  div
    b-col.col-12
      b-row.p-4
        label(style="color: black; font-weight: 600; display:block; text-align: center; font-size: 2.5rem; margin-top: 1.5rem") Quem somos
        br
    b-col.col-12
      label(style="color: black; font-size: 18px; display: flex; text-align: left; padding: 0rem 1.5rem") RHub é a união
        | &nbsp; profissional de duas mulheres brilhantes Andreza Silva e Deborah Kaufmann,
        | que decidiram encapsular todo o arsenal de conhecimentos e experiências, para dar ao
        | mercado algumas soluções, mostrando que é possível construir para e com as pessoas,
        | experiências com resultados significativos em ambientes de forte aceleração. Sem ferir,
        | adoecer ou esconder a singularidade e pluralidade que todos nós temos.
    b-col.center.mt-1.p-4
      label.center(style="color: black; font-size: 22px; font-weight: 600; display: flex;") Visualizamos o futuro para potencializar o presente.
    b-col.center.mt-1
      label.center(style="color: black; font-size: 22px; font-weight: 600; display: flex; padding: 0rem 1.5rem") Transformar a maneira como as pessoas trabalham, essa sim, é a vantagem competitiva.
    div
      b-col.col-12.center.mt-5
        b-avatar(:src="AndressaPngAvatar" size='14rem')
      b-col.col-12
        label.mt-4(style="color: rgb(0, 0, 0); font-weight: 500; font-size: 1.5rem") Andreza Silva
        b-col.col-xs-12.col-md-12.col-lg-12.center
          b-row.center
            label.center(style="color: rgb(0, 0, 0); font-weight: 400; font-size: 1rem") Founder
        b-col.col-12.center
          b-col.col-xs-12.col-md-12.col-lg-12.center
            b-avatar(rounded='sm' :src="LinkedinIcon" size='2rem')
      b-col.col-12.center.mt-5
        b-avatar(:src="DeboraPngAvatar" size='14rem')
      b-col.col-12
        label.mt-4(style="color: rgb(0, 0, 0); font-weight: 500; font-size: 1.5rem") Deborah Kaufmann
        b-col.col-xs-12.col-md-12.col-lg-12.center
          b-row.center
            label.center(style="color: rgb(0, 0, 0); font-weight: 400; font-size: 1rem") Co-Founder
        b-col.col-12.center
          b-col.col-xs-12.col-md-12.col-lg-12.center
            b-avatar(rounded='sm' :src="LinkedinIcon" size='2rem')
      //- b-col.col-12.center.mt-5
      //-   b-avatar(:src="DeboraPngAvatar" size='14rem')
      //- b-row.mt-2
      //-   b-col.col-12.center
      //-     label(style="color: rgb(0, 0, 0); font-weight: bold;") Débora Kaufmann
      //-   b-col.col-xs-12.col-md-12.col-lg-12.center
      //-     b-row.center
      //-       label.center Partner
      //-   b-col.col-12.center
      //-     b-col.col-xs-12.col-md-12.col-lg-12.center
      //-       b-avatar(rounded='sm' :src="LinkedinIcon" size='2rem')
//- div
//-   div.etapa-2.p-2.mt-3
//-     b-col.col-xs-12.col-md-12.col-lg-12.center
//-       label(style="font-size: 50px; color: rgb(209, 0, 118)") Sobre nós
//-     b-col.col-xs-12.col-md-12.col-lg-12.center.mt-2.p-3
//-       label.highlight-content-mobile RHub é a união de todo o arsenal de conhecimentos e experiências profissionais de
//-         | &nbsp; Andreza Silva, que decidiu encapsula-los para dar ao mercado algumas soluções,
//-         | mostrando que é possível sim, construir PARA e COM as pessoas, experiências com
//-         | resultados significativos em ambientes de forte aceleração. Sem ferir, adoecer ou esconder
//-         | a singularidade e pluralidade que todos nós temos.
//-     div.p-3
      //- b-col.col-12.center.mt-5
      //-   b-avatar(:src="AndressaPngAvatar" size='14rem')
      //- b-row.mt-2
      //-   b-col.col-12.center
      //-     label(style="color: rgb(209, 0, 118); font-weight: bold;") Andressa Silva
      //-   b-col.col-xs-12.col-md-12.col-lg-12.center
      //-     b-row.center
      //-       label.center Founder
      //-   b-col.col-12.center
      //-     b-col.col-xs-12.col-md-12.col-lg-12.center
      //-       b-avatar(rounded='sm' :src="LinkedinIcon" size='2rem')
      //- b-col.col-12.center.mt-5
      //-   b-avatar(:src="DeboraPngAvatar" size='14rem')
      //- b-row.mt-2
      //-   b-col.col-12.center
      //-     label(style="color: rgb(209, 0, 118); font-weight: bold;") Débora Kaufmann
      //-   b-col.col-xs-12.col-md-12.col-lg-12.center
      //-     b-row.center
      //-       label.center Partner
      //-   b-col.col-12.center
      //-     b-col.col-xs-12.col-md-12.col-lg-12.center
      //-       b-avatar(rounded='sm' :src="LinkedinIcon" size='2rem')
//-     div.p-3
//-       b-col.col-12.center
//-         b-row(style="text-align: center")
//-         label.highlight-content-mobile.p-3 Visualizamos o futuro para potencializar o presente. Transformar a maneira como as pessoas
//-           |&nbsp; trabalham, essa sim, é a vantagem competitiva.
//-       b-row.p-3.mt-4
//-         h1.highlight-content-mobile(style="font-size: 35px") Como fazemos?
//-         b-col.col-12.center.p-3
//-           label.highlight-content-mobile Através de soluções de Recrutamento e Seleção para o player de Tecnologia utilizando
//-             | &nbsp; metodologia proprietária
//-         b-col.col-12.center.p-3
//-           label.highlight-content-mobile Importante trazer aqui que em todos os nossos produtos e serviços a pluralidade é posta à
//-             | mesa, a fim de aumentar a gama de informações e assim reduzir os colapsos em toda jornada
//-             | do ciclo de gente, resultados dos negócios e sociedade.
</template>
<script>
import AndressaPngAvatar from '../assets/img/andressa-avatar.png';
import LinkedinIcon from '../assets/img/linkedin.png';
import DeboraPngAvatar from '../assets/img/debora-avatar.png';

export default {
  components: {
    LinkedinIcon,
    DeboraPngAvatar,
  },

  data() {

    return {
      AndressaPngAvatar,
      LinkedinIcon,
      DeboraPngAvatar,
    };

  },

  methods: {
  },

};
</script>
<style lang='css'>
  .etapa-2 {
    background-color: rgb(255, 255, 255);
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .highlight-content-mobile {
    font-weight: bold;
    font-family: system-ui;
    font-size: 17px;
    color: black;
  }
</style>
