<template lang='pug'>
      b-container.black.calc--header-navbar(fluid='' :class='isMobileClass' :style="parentPage == 'index' ? 'padding-left: 35px; padding-right: 35px;' : ''")
        .navbar--desktop(v-if='!isMobile')
          .max-width
            a(href='/')
              div.area-logo
                img.icon-width(src="../assets/img/RHUB_fundo_transparente__Principal-com-contorno.png")
            .col-links
              ul.links--list
                li.links--item
                  a(href='/#quem-somos') Quem somos
                li.links--item
                  a(href='/#consultoria') Consultoria
                li.links--item
                  a(href='/#recrutamento') Recrutamento e seleção
                li.links--item
                  a(href='/#treinamento') Treinamentos
                li.links--item
                  a(href='/#depoimentos') Depoimentos
                li.links--item
                  a(href='https://api.whatsapp.com/send?phone=5511983347404&text=Ol%C3%A1%20gostaria%20de%20saber%20mais%20sobre%20o%20RHub' target="_blank") Fale conosco
                li.links--item
                  a(href='https://campanhas.compliancestation.com.br/campanhademonstracao' target='_blank' v-if="parentPage == 'index'")
                    button.cta--compliance.gradient
                      .position-relative
                        | Experimente
                        svg(style='margin-left: 10px;' width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg')
                          g(filter='url(#filter0_d_158_5)')
                            path(d='M17.7136 5.06725H0.5V7.43275H17.7136V12L23.4636 6.25L17.7136 0.5V5.06725Z' fill='white')
                          defs
                            filter#filter0_d_158_5(x='0.281646' y='0.281646' width='23.4003' height='11.9367' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB')
                              feflood(flood-opacity='0' result='BackgroundImageFix')
                              fecolormatrix(in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha')
                              feoffset
                              fegaussianblur(stdDeviation='0.109177')
                              fecomposite(in2='hardAlpha' operator='out')
                              fecolormatrix(type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0')
                              feblend(mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_158_5')
                              feblend(mode='normal' in='SourceGraphic' in2='effect1_dropShadow_158_5' result='shape')
                  button.cta--compliance.gradient(v-else-if="parentPage == 'calculadora'")
                    .position-relative
                      | Calcule agora
                      svg(style='margin-left: 10px;' width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg')
                        g(filter='url(#filter0_d_158_5)')
                          path(d='M17.7136 5.06725H0.5V7.43275H17.7136V12L23.4636 6.25L17.7136 0.5V5.06725Z' fill='white')
                        defs
                          filter#filter0_d_158_5(x='0.281646' y='0.281646' width='23.4003' height='11.9367' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB')
                            feflood(flood-opacity='0' result='BackgroundImageFix')
                            fecolormatrix(in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha')
                            feoffset
                            fegaussianblur(stdDeviation='0.109177')
                            fecomposite(in2='hardAlpha' operator='out')
                            fecolormatrix(type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0')
                            feblend(mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_158_5')
                            feblend(mode='normal' in='SourceGraphic' in2='effect1_dropShadow_158_5' result='shape')
        .navbar--mobile(v-else='')
          a(href='/')
            .col-logo
              | sdf
          .col-burger
            i.fas.fa-bars.text-white(:class="{'active' : menuActived}" @click='toggleMenu')
        .popup-navbar--mobile(v-if='menuActived && isMobile' :style="parentPage == 'index' ? 'padding-left: 35px; padding-right: 35px;' : ''")
          .topbar-wrapper
            a(href='/')
              .col-logo
                | asd
            .col-burger
              i.fas.fa-times.fa-2x(@click='toggleMenu')
          .midbar-wrapper
            ul.links--list
              .links--wrapper
                li.links--item
                  a(@click='toggleMenu' :href="parentPage == 'index' ? '#sec--como-ajuda-negocio' : '/#sec--como-ajuda-negocio'") Serviços
                li.links--item
                  a(@click='toggleMenu' :href="parentPage == 'index' ? '#sec--segmentos' : '/#sec--segmentos'") Sobre nós
                li.links--item
                  a(@click='toggleMenu' :href="parentPage == 'index' ? '#sec--beneficios' : '/#sec--beneficios'") Benefícios
                li.links--item
                  a(@click='toggleMenu' href='/calculadora' target='_blank') Calculadora
                li.links--item
                  a(href='https://blog.compliancestation.com.br/' target='_blank') Blog
              li.links--item
                a(href='https://campanhas.compliancestation.com.br/campanhademonstracao' target='_blank' v-if="parentPage == 'index'")
                  button.cta--compliance.gradient
                    .position-relative
                      | Experimente
                      svg(style='margin-left: 10px;' width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg')
                        g(filter='url(#filter0_d_158_5)')
                          path(d='M17.7136 5.06725H0.5V7.43275H17.7136V12L23.4636 6.25L17.7136 0.5V5.06725Z' fill='white')
                        defs
                          filter#filter0_d_158_5(x='0.281646' y='0.281646' width='23.4003' height='11.9367' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB')
                            feflood(flood-opacity='0' result='BackgroundImageFix')
                            fecolormatrix(in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha')
                            feoffset
                            fegaussianblur(stdDeviation='0.109177')
                            fecomposite(in2='hardAlpha' operator='out')
                            fecolormatrix(type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0')
                            feblend(mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_158_5')
                            feblend(mode='normal' in='SourceGraphic' in2='effect1_dropShadow_158_5' result='shape')
                button.cta--compliance.gradient(v-else-if="parentPage == 'calculadora'")
                  .position-relative
                    | Calcule agora
                    svg(style='margin-left: 10px;' width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg')
                      g(filter='url(#filter0_d_158_5)')
                        path(d='M17.7136 5.06725H0.5V7.43275H17.7136V12L23.4636 6.25L17.7136 0.5V5.06725Z' fill='white')
                      defs
                        filter#filter0_d_158_5(x='0.281646' y='0.281646' width='23.4003' height='11.9367' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB')
                          feflood(flood-opacity='0' result='BackgroundImageFix')
                          fecolormatrix(in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha')
                          feoffset
                          fegaussianblur(stdDeviation='0.109177')
                          fecomposite(in2='hardAlpha' operator='out')
                          fecolormatrix(type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0')
                          feblend(mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_158_5')
                          feblend(mode='normal' in='SourceGraphic' in2='effect1_dropShadow_158_5' result='shape')
</template>
<script>

export default {

  props: {
    screenWidth: Number,
  },

  data() {

    return {};

  },

};
</script>
<style lang='scss' scoped>
  .black {
    background-color: black;
    color: black;
  }
  .icon-width {
    width: 120px;
    height: 120px;
  }
  .first-divider-info {
    height: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .first-divider {
    background-image: url('../assets/img/foto-cidade-ok.webp');
    height: 80vh;
    max-height: 960px;
  }
  .area-logo {
    max-width: 150px;
    max-height: 150px;
  }
  .none {
    display: none;
  }
  .vl {
    border-left: 5px solid rgb(255, 255, 255);
    height: 150px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .subtitle-first-divider {
    color: white;
    font-size: 25px;
  }
  .max-width {
    max-width: 100%;
  }
  .full {
    width: 100%;
  }
  .text-center {
    text-align: center;
  }
.max-width {
    max-width: 1235px;
    margin: 0 auto;
    width: 100%;
}

.calc--header-navbar {
    color: white;
    background-image: linear-gradient(
        180deg,
        rgba(10, 30, 71, 0.5) 0%,
        rgba(39, 52, 114, 0) 100%
    );
    min-height: 100px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;

    .navbar--desktop {
        display: flex;
        height: 100%;

        .max-width {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .col-links {
            flex: 1;
            max-width: 800px;

            .links--list {
                display: flex;
                justify-content: space-between;
                padding-left: 0;
                list-style: none;
                display: flex;
                flex-direction: row;
                margin-bottom: 0;

                .links--item {
                    display: flex;
                    align-items: center;
                    font-weight: 600;

                    a {
                        color: unset;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .navbar--mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .popup-navbar--mobile {
        position: fixed;
        z-index: 1;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        background-color: rgba(39, 52, 114, 0.98);
        padding: 0 15px;
        display: flex;
        flex-direction: column;

        .topbar-wrapper {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .midbar-wrapper {
            flex: 1;

            .links--list {
                padding-left: 0;
                list-style: none;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .links--wrapper {
                    .links--item {
                        font-size: 24px;
                        padding-bottom: 35px;
                        text-align: center;

                        a {
                            color: unset;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
