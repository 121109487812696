<template lang='pug'>
div.third-divider.center
  div.p-5(style="margin-top: 80px; max-width: 1140px;")
      b-row
        b-col.col-12.flex
          h1(style="color: white; font-size: 70px;font-weight: 1000;") Dados do
        b-col.col-12.flex
          h1(style="color: white; font-size: 70px;font-weight: 1000;") mercado
      b-row.mt-4
        b-col.col-12.flex
          label.content-text-simple(style="color: white;") Pesquisas da Gartner e Robert Half mostraram que 4 em cada 10 executivos afirmaram terem
        b-col.col-12.flex
          label.content-text-simple(style="color: white") feito pelo menos uma contratação equivocada, principalmente quando se refere a soft skills
        b-col.col-12.flex
          label.content-text-simple(style="color: white") contendo os principais motivos das queixas dos funcionários:
      b-row.mt-5
        b-col.col-12.flex
          h1(style="color: white; font-size: 40px;font-weight: 1000;") Quer mais dados?
        b-col.col-12.flex
          label.content-text-simple(style="color: white") Deloite compartilhou pesquisa global com principais motivos de turnover nas empresas
      b-row.mt-3
        b-col.col-xs-6.col-md-6.col-lg-5.col-xl-4
          b-row(style="text-align: left;")
            b-col.col-6(style="align-items: center; display: flex;")
              label.statistics-numbers.p-1 30%
              label.content-text-simple-statistics(style="color: white") Falta de reconhecimento
        b-col.col-xs-6.col-md-6.col-lg-5.col-xl-4
          b-row(style="text-align: left;")
            b-col.col-6(style="align-items: center; display: flex;")
              label.statistics-numbers.p-1 30%
              label.content-text-simple-statistics(style="color: white") Falta de reconhecimento
      b-row.mb-5
        b-col.col-xs-6.col-md-6.col-lg-5.col-xl-4
          b-row(style="text-align: left;")
            b-col.col-6(style="align-items: center; display: flex;")
              label.statistics-numbers.p-1 30%
              label.content-text-simple-statistics(style="color: white") Falta de reconhecimento
        b-col.col-xs-6.col-md-6.col-lg-5.col-xl-4
          b-row(style="text-align: left;")
            b-col.col-6(style="align-items: center; display: flex;")
              label.statistics-numbers.p-1 30%
              label.content-text-simple-statistics(style="color: white") Falta de reconhecimento
      b-row.mt-3.mb-5.w-100(style="display: flex; justify-content: end")
        div(style="max-width: 230px; max-height: 230px;")
          img(src="../assets/img/RHUB_fundo_transparente__EscalaCinza.webp"  width="165" height="165" alt="RHUB Logo Icon Black White")
</template>
<script>

export default {

  data() {

    return {};

  },

};
</script>
<style lang='css'>
  .content-text-simple-statistics {
    font-weight: 300;
    font-size: 19px;
  }
  .statistics-numbers {
    color: rgb(209, 0, 118);
    font-size: 70px;
    font-weight: 1000;
    letter-spacing: 5px;
  }
  .statistics-numbers-white {
    color: rgb(255, 255, 255);
    font-size: 70px;
    font-weight: 1000;
    letter-spacing: 5px;
  }
  .card-statistics {
    background-color: rgba(33, 37, 41);
  }
  .content-text-simple {
    font-weight: 300;
    font-size: 21px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .third-divider {
    background-image: linear-gradient(to right, black, rgba(3, 3, 3, 0.7)), url('../assets/img/foto-cidade-ok.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

</style>
