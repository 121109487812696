<template lang='pug'>
div.numbers-div
    b-row.max-width(style="margin: 0px;")
      b-col.col-xs-12.col-md-12.col-lg-12.col-xl-6(style="padding: 0px;")
        div.screen-numbers-div
      b-col.col-xs-12.col-md-12.col-lg-12.col-xl-6
        b-row.mt-4.p-3
          b-col
            b-row
              b-col.col-12
                label.statistics-numbers 81%
            b-row.center
              b-col.col-9
                label.content-text-simple Dos entrevistados afirmam que os impactos de
                  | uma contratação ruim são mais severos hoje do
                  | que há um ano
            b-row
              b-col.col-12
                label.statistics-numbers 68%
            b-row.center
              b-col.col-9
                label.content-text-simple Acreditam que focar nas habilidades técnicas, em
                  | detrimento das comportamentais, contribui para
                  | as falhas
            b-row
              b-col.col-12
                label.statistics-numbers 65%
            b-row.center
              b-col.col-9
                label.content-text-simple Consideram que acelerar demais o processo
                  |  é um dos fatores que mais prejudicam
            b-row
              b-col.col-12(style="display: flex; justify-content: end")
                img(src="../assets/img/RHUB_fundo_transparente__Principal-com-contorno.webp" width="165" height="165" alt="RHUB Logo Icon")
</template>
<script>

export default {

  data() {

    return {};

  },

};
</script>
<style lang='css'>
  .screen-numbers-div {
    height: 100vh;
    max-height: 980px;
    background-position: center;
    width: 100%;
    background-image: url('../assets/img/work-woman.webp');
  }
  .numbers-div {
    height: auto;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .content-text-simple {
    font-weight: 300;
    font-size: 2px;
  }
  .statistics-numbers {
    color: rgb(209, 0, 118);
    font-size: 70px;
    font-weight: 1000;
    letter-spacing: 5px;
  }
  .max-width {
    max-width: 100%;
  }
</style>
